import React, { useMemo } from 'react';

import { Box, Typography, MenuItem, Button, useTheme } from '@material-ui/core';
import { GridColDef } from '@mui/x-data-grid';
import { FormProvider } from 'react-hook-form';
import noop from 'lodash/noop';
import { RiListSettingsLine } from 'react-icons/ri';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import { CgArrowsExpandRight } from 'react-icons/cg';
import { IoSearchSharp } from 'react-icons/io5';

import { AppSelect } from '@vyce/core/src/components/inputs';
import { AppDataGrid, AppIconButton } from '@vyce/core/src/components';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';
import { FiltersDialog } from '@vyce/core/src/components/Dashboard/FiltersDialog';
import { AppSearchInput } from '@vyce/core/src/components/inputs/AppSearchInput';
import { ExpandedMenuButton, StyledBadge } from '@vyce/core/src/components/Dashboard/ExpandedMenuButton';

import { useWidgetData } from './hooks';
import { Props } from './types';
import { useStyles } from './styles';

export const WidgetTemplate = ({
  getColumns,
  ...restProps
}: Props &
  WidgetWrapperProps & {
    getColumns: (cnaViewWorker: boolean) => GridColDef[];
  }) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    workers,
    locationOptions,
    selectedLocationId,
    total,
    sortModel,
    methods,
    filterSettings,
    isFilterDialogOpen,
    cnaViewWorker,
    loading,
    isMobile,
    isSmallView,
    isShowSearchField,
    isFilterWasActivated,
    downloadButtonLoading,
    getSharedFile,
    onSubmit,
    restoreFilters,
    openFilterDialog,
    closeFilterDialog,
    handlePageChange,
    setSelectedLocationId,
    handleSortModelChange,
    handleSearchChange,
    setSmallView,
    setShowSearchFieldTrue,
  } = useWidgetData(restProps);

  const columns = useMemo(() => getColumns(cnaViewWorker), [cnaViewWorker, getColumns]);

  const handleChange = (event: any) => {
    setSelectedLocationId(event.target.value);
  };

  const bigHeaderView = useMemo(
    () => (
      <>
        <AppSearchInput
          onChange={handleSearchChange}
          isExtraSmall
          isBorder
          smallRounding
          expanded={!isMobile}
        />

        <StyledBadge color="primary" variant="dot" invisible={!isFilterWasActivated}>
          <AppIconButton variant="paper" isSmall isBorder onClick={openFilterDialog}>
            <RiListSettingsLine size="20px" color={theme.palette.text.primary} />
          </AppIconButton>
        </StyledBadge>

        <AppIconButton
          variant="paper"
          isSmall
          isBorder
          onClick={getSharedFile}
          disabled={downloadButtonLoading}>
          <RiDownloadCloud2Line size="20px" color={theme.palette.text.primary} />
        </AppIconButton>
      </>
    ),
    [isMobile, handleSearchChange, isFilterWasActivated, downloadButtonLoading, openFilterDialog]
  );

  return (
    <WidgetWrapper
      {...restProps}
      withExpandButton={!isSmallView}
      setSmallViewStatus={(value: boolean) => {
        setSmallView(value);
      }}
      renderExtraControls={({ handleFullscreenStatusChange }) => (
        <>
          {isSmallView ? (
            <ExpandedMenuButton hideBadge={!isFilterWasActivated}>
              <>
                <Button
                  onClick={handleFullscreenStatusChange}
                  endIcon={<CgArrowsExpandRight size="16px" />}
                  variant="outlined"
                  size="small"
                  hidden={isMobile}>
                  Expanded
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<RiDownloadCloud2Line size="16px" />}
                  onClick={getSharedFile}>
                  Download
                </Button>

                {!isShowSearchField && (
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<IoSearchSharp size="16px" />}
                    onClick={setShowSearchFieldTrue}>
                    Search
                  </Button>
                )}

                <StyledBadge color="primary" variant="dot" invisible={!isFilterWasActivated}>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<RiListSettingsLine size="16px" />}
                    onClick={openFilterDialog}>
                    Filter
                  </Button>
                </StyledBadge>
              </>
            </ExpandedMenuButton>
          ) : (
            bigHeaderView
          )}
        </>
      )}>
      <Box width="100%" height="100%" display="flex" flexDirection="column" gridGap={12}>
        <Box width="100%" display="flex" flexDirection="column" gridGap={12}>
          {isSmallView && isShowSearchField && (
            <Box width="200px">
              <AppSearchInput onChange={handleSearchChange} isSmall isBorder expanded={!isMobile} />
            </Box>
          )}
          <Box width="100%" display="flex" gridGap={12} alignItems="center">
            <Typography color="primary" className={classes.count}>
              {total} workers
            </Typography>
            <Typography>on</Typography>
            <AppSelect
              onChange={handleChange}
              value={selectedLocationId}
              labelId="select-location"
              fullWidth
              className={classes.selector}>
              {locationOptions.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </AppSelect>
          </Box>
        </Box>

        <AppDataGrid
          rows={workers}
          getRowId={row => row.user_id}
          columns={columns}
          noPaper
          rowHeight={80}
          height="calc(100% - 50px)"
          rowCount={total}
          sortModel={sortModel}
          pageSize={GRID_PAGE_SIZE}
          paginationMode="server"
          sortingMode="server"
          rowsPerPageOptions={[GRID_PAGE_SIZE]}
          onPageChange={handlePageChange}
          onSortModelChange={handleSortModelChange}
          disableSelectionOnClick
        />
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(noop)}>
          <FiltersDialog
            isOpen={isFilterDialogOpen}
            onClose={closeFilterDialog}
            filterSettings={filterSettings}
            onSubmit={onSubmit}
            restoreFilters={restoreFilters}
            loading={loading}
          />
        </form>
      </FormProvider>
    </WidgetWrapper>
  );
};
