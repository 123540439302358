import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Typography,
  Paper,
  useTheme,
  CircularProgress,
  makeStyles,
  Theme,
  createStyles,
  FilledInput,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { BiMicrophone } from 'react-icons/bi';
import { FaPlay } from 'react-icons/fa6';
import { ReactMic, ReactMicStopEvent } from 'react-mic';
import { HiOutlineTrash } from 'react-icons/hi';
import { GrAttachment } from 'react-icons/gr';
import { useDropzone } from 'react-dropzone';
import { FaChevronRight } from 'react-icons/fa';

import { useAppTextFieldStyles } from '@vyce/core/src/components/inputs';
import { DeviceContext } from '@vyce/core/src/contexts';
import { AppIconButton, ConfirmDialog } from '@vyce/core/src/components';
import { blobToBase64 } from '@vyce/core/src/utils';

import { AIChatContext } from '../context';
import { monochrome } from '../../../theme/styles';
import { FileCard } from './FileCard';
import { createThreadFileRequest } from '../../../api/ai';
import { NotificationContext } from '../../../contexts/notificationContext';
import { AIChatFile } from '../../../types';
import { AI_CHAT_FILE_ACCEPTS, DOCUMENT_ANALYSIS_IDEAS } from '../constants';

const controlHeight = 56;
const maxFileSize = 10485760; // 10MB in bytes

interface Props {
  style?: React.CSSProperties;
  attachFile?: boolean;
  placeholder?: string;
}

export const AskAIInput: React.FC<Props> = ({ style, attachFile, placeholder = 'Ask me anything' }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { handleServerError } = useContext(NotificationContext);
  const inputClasses = useAppTextFieldStyles({});
  const { isMobile } = useContext(DeviceContext);
  const [question, setQuestion] = useState<string>('');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  const [showAudioStream, setShowAudioStream] = useState<boolean>(false);
  const { loading, addUserMessage, speechToText, setLoadingTrue, setLoadingFalse, setFiles } =
    useContext(AIChatContext);
  const [timer, setTimer] = useState<number>(0);
  const [timerActive, setTimerActive] = useState<boolean>(false);
  const [audio, setAudio] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(true);
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<AIChatFile | undefined>(undefined);
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false);

  const createThreadFile = async (file: string, fileName: string): Promise<string | undefined> => {
    try {
      const res = await createThreadFileRequest(file, fileName);
      return res.data.body.fileId as string;
    } catch (e) {
      handleServerError(e);
      return undefined;
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    const oversizedFiles = acceptedFiles.filter(file => file.size > maxFileSize);

    if (oversizedFiles.length > 0) {
      setOpenWarningDialog(true);
      return;
    }
    setLoadingTrue();
    await acceptedFiles.map(async fileItem => {
      try {
        const reader = new FileReader();
        reader.onloadend = async () => {
          let base64File: string | undefined;
          const fileBlob = new Blob([fileItem], { type: fileItem.type });
          base64File = await blobToBase64(fileBlob);
          const fileId = await createThreadFile(base64File, fileItem.name);

          setFileId(fileId);
          if (fileItem.name && fileItem.size && fileId) {
            const newFile = { name: fileItem.name, size: fileItem.size, fileId };
            setFile(newFile);
            setFiles((value: AIChatFile[]) => [...value, newFile]);
          }
          setLoadingFalse();
        };

        if (fileItem) {
          reader?.readAsDataURL(fileItem);
        }
      } catch (e) {
        setLoadingFalse();
        console.error(e);
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    multiple: false,
    accept: AI_CHAT_FILE_ACCEPTS,
  });

  const iconButtonStyles = {
    height: controlHeight,
    width: controlHeight,
    borderRadius: 16,
    backgroundColor: theme.palette.primary.main,
  };

  const gap = isMobile ? 8 : 12;

  const formattedTime = useMemo(() => {
    const seconds = timer % 60;
    const minutes = Math.floor(timer / 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }, [timer]);

  const toggleTimer = () => {
    setTimerActive(!timerActive);
  };

  const resetTimer = () => {
    setTimer(0);
    setTimerActive(false);
  };

  const showSendButton = useMemo(() => !!question || isRecording, [question, isRecording]);

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && !e.metaKey && !!question) {
      e.preventDefault();
      addUserMessage(question, fileId);
      setFile(undefined);
      setFileId(undefined);
      setQuestion('');
    }

    if (e.key === 'Enter' && e.metaKey && !!question) {
      setQuestion(question + '\n');
    }
  };

  const onButtonClick = () => {
    if (question) {
      addUserMessage(question, fileId);
      setFile(undefined);
      setFileId(undefined);
      setQuestion('');
    } else if (isRecording) {
      stopRecording();
    } else {
      setAudio('');
      setIsRemoved(false);
      toggleTimer();
      setShowAudioStream(true);
    }
  };

  const handleDocumentAnalysisIdeaClick = (idea: string) => {
    addUserMessage(idea, fileId);
    setFile(undefined);
    setFileId(undefined);
  };

  const onStop = useCallback(async (recordedBlob: ReactMicStopEvent) => {
    const base64Audio = await blobToBase64(recordedBlob.blob);
    setAudio(base64Audio);
  }, []);

  const stopRecording = () => {
    setShowAudioStream(false);
    toggleTimer();
    resetTimer();
  };

  useEffect(() => {
    setTimeout(() => setIsRecording(showAudioStream));
  }, [showAudioStream]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (timerActive) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
    } else if (!timerActive && timer !== 0) {
      clearInterval(interval!);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timerActive]);

  useEffect(() => {
    if (audio && !isRemoved) {
      speechToText(audio);
    }
  }, [audio, isRemoved]);

  return (
    <Paper variant="outlined" className={classes.container} style={style}>
      <Box display="flex" flexDirection="column" gridGap={gap}>
        {!!file && (
          <Box>
            <FileCard file={file} setFile={setFile} />

            <Box display="flex" className={classes.documentAnalysisIdeas} gridGap={16}>
              <Typography className={classes.noWrap}>Analyze this document:</Typography>
              {DOCUMENT_ANALYSIS_IDEAS.map(idea => (
                <Box
                  onClick={() => handleDocumentAnalysisIdeaClick(idea)}
                  className={classes.ideasContainer}
                  key={idea}>
                  <FaChevronRight size={12} />
                  {idea}
                </Box>
              ))}
            </Box>
          </Box>
        )}

        <Box display="flex" gridGap={gap}>
          <Box alignItems="flex-end" display="flex" flex={1}>
            <Box
              display="flex"
              flex={1}
              style={{
                visibility: showAudioStream ? 'visible' : 'hidden',
                width: showAudioStream ? 'auto' : 0,
              }}
              gridGap={gap}>
              <AppIconButton
                style={{
                  ...iconButtonStyles,
                  backgroundColor: isMobile
                    ? theme.palette.background.default
                    : theme.palette.background.paper,
                  border: isMobile ? 'none' : `2px solid ${theme.palette.primary.main}`,
                }}
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={() => {
                  setAudio('');
                  setIsRemoved(true);
                  stopRecording();
                }}>
                <HiOutlineTrash color={theme.palette.primary.main} size="24px" />
              </AppIconButton>

              <Box
                display="flex"
                flex={1}
                style={{ backgroundColor: theme.palette.primary.main }}
                gridGap={12}
                alignItems="center"
                borderRadius={26}
                padding="0 16px">
                <Box minWidth={42}>
                  <Typography style={{ color: '#FFFFFF', fontSize: 15 }}>{formattedTime}</Typography>
                </Box>
                <ReactMic
                  record={isRecording}
                  visualSetting="sinewave"
                  className={isMobile ? 'sound-wave-mobile' : 'sound-wave'}
                  onStop={onStop}
                  strokeColor={theme.palette.background.paper}
                  backgroundColor={theme.palette.primary.main}
                />
              </Box>
            </Box>

            {!showAudioStream && (
              <FormControl fullWidth variant="filled">
                <InputLabel
                  style={{ left: attachFile ? 38 : 12 }}
                  shrink={focused || !!question}
                  htmlFor="ai-chat-input">
                  {placeholder}
                </InputLabel>
                <FilledInput
                  autoFocus
                  multiline
                  maxRows={4}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  onChange={e => setQuestion(e.target.value)}
                  value={question}
                  onKeyDown={handleKeyPress}
                  id="ai-chat-input"
                  classes={inputClasses}
                  style={{
                    backgroundColor: isMobile ? theme.palette.background.paper : undefined,
                  }}
                  disableUnderline
                  startAdornment={
                    attachFile ? (
                      <Box {...getRootProps()} style={{ cursor: 'pointer' }} paddingLeft={1}>
                        <input {...getInputProps()} />

                        <GrAttachment size={24} color={theme.palette.primary.main} />
                      </Box>
                    ) : undefined
                  }
                />
              </FormControl>
            )}
          </Box>

          <Box display="flex" alignItems="flex-end" gridGap={16}>
            <AppIconButton
              isBorder
              style={iconButtonStyles}
              disabled={loading}
              borderColor={theme.palette.primary.main}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={onButtonClick}>
              {loading ? (
                <CircularProgress size={24} style={{ color: '#FFFFFF' }} />
              ) : showSendButton ? (
                <FaPlay color="#FFFFFF" size="20px" />
              ) : (
                <BiMicrophone color="#FFFFFF" size="24px" />
              )}
            </AppIconButton>
          </Box>

          <ConfirmDialog
            handleClose={() => {
              setOpenWarningDialog(false);
            }}
            open={openWarningDialog}
            subtitle={`File size is too large. Please upload a file that is less than 10MB.`}
            confirmText="Ok, Close"
            title="File too large"
            handleConfirm={() => setOpenWarningDialog(false)}
          />
        </Box>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('xs')]: {
        margin: 0,
        padding: 0,
        border: 0,
        backgroundColor: 'transparent',
      },
    },
    noWrap: {
      whiteSpace: 'nowrap',
      lineHeight: '22px',
      fontSize: 13,
    },
    closeButton: {
      position: 'absolute',
      top: -10,
      right: -10,
      border: `1px solid ${monochrome.mediumlight}`,
      '&:hover': {
        opacity: 1,
        backgroundColor: theme.palette.background.paper,
      },
    },
    documentAnalysisIdeas: {
      paddingTop: 12,
      overflowY: 'hidden',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none', // IE and Edge
      'scrollbar-width': 'none', // Firefox
    },
    ideasContainer: {
      fontWeight: 500,
      color: theme.palette.primary.dark,
      display: 'flex',
      alignItems: 'center',
      gridGap: 4,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      lineHeight: '22px',
      fontSize: 13,
    },
  })
);
