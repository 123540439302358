import React, { useContext, useEffect, useMemo, useState } from 'react';

import { AiOutlinePlus } from 'react-icons/ai';
import { Button, useTheme } from '@material-ui/core';

import { PaySchedule, Shift, Team, TimeAndAttendanceLocation } from '@vyce/core/src/types';
import { createInvitesRequest } from '@vyce/core/src/api/legend/invites';
import { CompanyFilter } from '@vyce/core/src/components/CompanyFilter';
import { getPaySchedulesRequest } from '@vyce/core/src/api/legend/pay';
import { getCompanyTeamsRequest } from '@vyce/core/src/api/legend/teams';
import { CompanyTeamsSortingOption } from '@vyce/core/src/api/types';
import { UniversalInviteDialog } from '@vyce/core/src/views/invites';
import { UniversalInviteProvider } from '@vyce/core/src/contexts';
import { useBooleanState } from '@vyce/core/src/hooks';
import { getSitesRequest } from '@vyce/core/src/api/time';
import { siteConverter } from '@vyce/core/src/utils/location';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';

import { useActions, useTypedSelector } from '../hooks';

export const DEFAULT_TEAMS_LIST_SORTING = {
  field_name: 'name' as CompanyTeamsSortingOption,
  desc: true,
};

export const LegendInviteButton = () => {
  const { handleServerError } = useContext(NotificationContext);
  const [teams, setTeams] = useState<Team[]>([]);
  const [paySchedules, setPaySchedules] = useState<PaySchedule[]>([]);
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [locations, setLocations] = useState<TimeAndAttendanceLocation[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [isInviteFormOpen, setOpenInviteForm, setCloseInviteForm] = useBooleanState(false);
  const { access_token, companies } = useTypedSelector(state => state.helper);
  const theme = useTheme();

  const contextValue = useMemo(
    () => ({
      isLegend: true,
      token: access_token,
      teams,
      shifts,
      locations,
      disabledContactInput: !selectedCompanyId,
      companyId: selectedCompanyId,
      open: isInviteFormOpen,
      paySchedules,
      onClose: setCloseInviteForm,
      createInvitesRequest,
    }),
    [
      access_token,
      teams,
      selectedCompanyId,
      isInviteFormOpen,
      paySchedules,
      setCloseInviteForm,
      shifts,
      locations,
    ]
  );

  const getCompanyData = async (companyId: string) => {
    try {
      const teamsRes = await getCompanyTeamsRequest({
        companyId,
        requestData: { order_by: [DEFAULT_TEAMS_LIST_SORTING] },
      });
      setTeams(teamsRes.data.items);
      const paySchedulesRes = await getPaySchedulesRequest({
        companyId,
        limit: 1000,
        offset: 0,
      });
      setPaySchedules(paySchedulesRes.data.items || []);

      const resTime = await getSitesRequest(companyId, {
        order_by: [],
        limit: 1000,
        show_deleted: false,
        only_active: true,
      });
      const { locations, shifts } = siteConverter(resTime.data.items);
      setLocations(locations);
      setShifts(shifts);
    } catch (e) {
      handleServerError(e);
    }
  };

  useEffect(() => {
    if (selectedCompanyId) {
      getCompanyData(selectedCompanyId);
    }
  }, [selectedCompanyId]);

  return (
    <UniversalInviteProvider value={contextValue}>
      <Button
        onClick={setOpenInviteForm}
        size="small"
        style={{ backgroundColor: theme.palette.background.paper }}
        startIcon={<AiOutlinePlus />}
        variant="outlined"
        data-tour="invite"
        color="primary">
        Invite New Members
      </Button>

      <UniversalInviteDialog>
        <CompanyFilter
          companies={companies}
          onCompanyChange={(e: any, company: any) => setSelectedCompanyId(company?.uuid)}
        />
      </UniversalInviteDialog>
    </UniversalInviteProvider>
  );
};
