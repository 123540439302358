import { useContext, useEffect, useState } from 'react';

import type { GridSortModel } from '@mui/x-data-grid';

import { useBooleanState } from '@vyce/core/src/hooks';
import {
  getTeamMembersWithoutRateRequest,
  getCompaniesWorkersWidgetSharedData,
} from '@vyce/core/src/api/time';
import { formatTimeSortModel } from '@vyce/core/src/utils/sorting';
import { TeamMembersWithoutRateDTO } from '@vyce/core/src/api/types';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';

import { Props } from '../types';
import { generateExcelFileLink } from '../../../../utils';

const defaultSortModel: GridSortModel = [{ field: 'first_name', sort: 'desc' }];

export const useWidgetData = ({ selectedCompanyId }: Props) => {
  const [workers, setWorkers] = useState<TeamMembersWithoutRateDTO[]>([]);
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(false);
  const [downloadButtonLoading, setDownloadButtonLoadingTrue, setDownloadButtonLoadingFalse] =
    useBooleanState(false);
  const { handleServerError } = useContext(NotificationContext);
  const { isMobile } = useContext(DeviceContext);

  const { sortModel, offset, total, setTotal, handleSortModelChange, handlePageChange, handleSearchChange } =
    useTable({
      defaultSortModel,
    });

  const getMembers = async () => {
    if (!selectedCompanyId) {
      return;
    }

    setLoadingTrue();
    try {
      const { data } = await getTeamMembersWithoutRateRequest({
        companyId: selectedCompanyId,
        payload: {
          offset: offset,
          limit: GRID_PAGE_SIZE,
          order_by: formatTimeSortModel<TeamMembersWithoutRateDTO>(sortModel),
        },
      });
      setTotal(data.count);
      setWorkers(data.items);
      setLoadingFalse();
    } catch (e) {
      setLoadingFalse();
      handleServerError(e);
    }
  };

  const getSharedFile = async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      setDownloadButtonLoadingTrue();
      const res = await getCompaniesWorkersWidgetSharedData({
        companyId: selectedCompanyId,
        target: 'no_rate',
        payload: {
          offset: offset,
          limit: GRID_PAGE_SIZE,
          order_by: formatTimeSortModel<TeamMembersWithoutRateDTO>(sortModel),
        },
      });
      generateExcelFileLink(res);
    } catch (e) {
      handleServerError(e);
    } finally {
      setDownloadButtonLoadingFalse();
    }
  };

  useEffect(() => {
    getMembers();
  }, [selectedCompanyId, offset, sortModel]);

  return {
    workers,
    total,
    loading,
    sortModel,
    isMobile,
    downloadButtonLoading,
    getSharedFile,
    handlePageChange,
    handleSortModelChange,
    handleSearchChange,
  };
};
