import { Box, useTheme } from '@material-ui/core';
import { RiDownloadCloud2Line } from 'react-icons/ri';

import { PageLoading, AppIconButton } from '@vyce/core/src/components';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { useStyles } from './styles';
import { Props } from './types';
import { TopPerformingContent } from './components';
import { useWidgetData } from './hooks';

export const TopPerformingWidget = (props: Props & WidgetWrapperProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    loading,
    topPerformingHours,
    topPerformingWorkers,
    cnaViewWorker,
    downloadButtonLoading,
    getSharedFile,
  } = useWidgetData(props);

  return (
    <WidgetWrapper
      {...props}
      renderExtraControls={() => (
        <>
          <AppIconButton
            variant="paper"
            isSmall
            isBorder
            onClick={getSharedFile}
            disabled={downloadButtonLoading}>
            <RiDownloadCloud2Line size="20px" color={theme.palette.text.primary} />
          </AppIconButton>
        </>
      )}>
      <Box className={classes.widgetContainer}>
        {loading ? (
          <PageLoading />
        ) : (
          <TopPerformingContent
            topPerformingWorkers={topPerformingWorkers}
            cnaViewWorker={cnaViewWorker}
            topPerformingHours={topPerformingHours}
          />
        )}
      </Box>
    </WidgetWrapper>
  );
};
