import { useContext, useEffect, useMemo, useState } from 'react';

import { useBooleanState } from '@vyce/core/src/hooks';
import { TIME_INTERFACE_PERMISSIONS, TopPerformingHours, TopPerformingWorker } from '@vyce/core/src/types';
import {
  getTopPerformingTeamMembersLatWeekRequest,
  getTopPerformingWorkersLastWeekSharedData,
} from '@vyce/core/src/api/time';

import { Props } from '../types';
import { defaultWidgetHours } from '../config';
import { NotificationContext } from '../../../../contexts/notificationContext';
import { generateExcelFileLink } from '../../../../utils';

export const useWidgetData = ({ selectedCompanyId, userPermissions }: Props) => {
  const [loading, setLoadingTrue, setLoadingFalse] = useBooleanState(true);
  const [downloadButtonLoading, setDownloadButtonLoadingTrue, setDownloadButtonLoadingFalse] =
    useBooleanState(false);
  const [topPerformingWorkers, setTopPerformingWorkers] = useState<TopPerformingWorker[]>([]);
  const [topPerformingHours, setTopPerformingHours] = useState<TopPerformingHours>(defaultWidgetHours);
  const { handleServerError } = useContext(NotificationContext);

  const cnaViewWorker = useMemo(
    () => (userPermissions?.length ? userPermissions.includes(TIME_INTERFACE_PERMISSIONS.USERS) : true),
    [userPermissions]
  );

  const fetchData = async () => {
    if (!selectedCompanyId) return;
    setLoadingTrue();

    try {
      const { data } = await getTopPerformingTeamMembersLatWeekRequest({
        companyId: selectedCompanyId,
      });

      const { top_performing_workers, ...restData } = data;

      setTopPerformingWorkers(top_performing_workers);
      setTopPerformingHours({ ...defaultWidgetHours, ...restData });

      setLoadingFalse();
    } catch (e) {
      handleServerError(e);
      setLoadingFalse();
    }
  };

  const getSharedFile = async () => {
    if (!selectedCompanyId) {
      return;
    }
    try {
      setDownloadButtonLoadingTrue();
      const res = await getTopPerformingWorkersLastWeekSharedData({
        companyId: selectedCompanyId,
      });
      generateExcelFileLink(res);
    } catch (e) {
      handleServerError(e);
    } finally {
      setDownloadButtonLoadingFalse();
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedCompanyId]);

  return {
    loading,
    downloadButtonLoading,
    getSharedFile,
    topPerformingWorkers,
    topPerformingHours,
    cnaViewWorker,
  };
};
