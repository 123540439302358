import React, { useContext } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import { DeviceContext } from '@vyce/core/src/contexts/deviceContext';
import { TeamModuleContext } from '@vyce/core/src/contexts';

import { EmployeesForTransferList } from './EmployeesForTransferList';
import { MAIN_CONTAINER_ID } from '../../../constants';

interface Props {
  open: boolean;
  setOpen: Function;
}

export const TransferEmployeesDialog: React.FC<Props> = ({ open, setOpen }) => {
  const { isDesktop, isMobile } = useContext(DeviceContext);
  const { selectionModel, handleTransfer } = useContext(TeamModuleContext);

  return (
    <Dialog
      fullScreen={isMobile}
      container={document.getElementById(MAIN_CONTAINER_ID)}
      open={open}
      maxWidth="lg"
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Add team members from Payroll module</DialogTitle>

      <DialogContent style={{ width: isDesktop ? '1000px' : '100%' }}>
        <DialogContentText>Chose team members for transfer</DialogContentText>
        {open && <EmployeesForTransferList />}
      </DialogContent>

      <DialogActions>
        <Button size="large" variant="outlined" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          size="large"
          color="primary"
          cy-test-id="add-existing-user-to-team-button"
          onClick={handleTransfer}
          disabled={!selectionModel.length}
          variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
