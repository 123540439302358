import React, { useMemo } from 'react';

import type { GridColDef } from '@mui/x-data-grid';
import { useTheme } from '@material-ui/core';

import { GridCellWithAvatar, AppIconButton } from '@vyce/core/src/components';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';
import { RiDownloadCloud2Line } from 'react-icons/ri';

import { AppDataGrid } from '@vyce/core/src/components';

import { useWidgetData } from './hooks';
import { Props } from './types';

/* Team Members Missing a Rate */

export const TeamMembersMissingRateWidget = (props: Props & WidgetWrapperProps) => {
  const {
    workers,
    loading,
    sortModel,
    total,
    downloadButtonLoading,
    getSharedFile,
    handlePageChange,
    handleSortModelChange,
  } = useWidgetData(props);
  const theme = useTheme();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'first_name',
        headerName: 'Name',
        renderCell: params => (
          <GridCellWithAvatar
            avatarUrl={params.row.photo_url}
            avatarPlaceholder={getAvatar(params.row.gender)}
            link={`/time/users/${params.row?.first_name}_${params.row?.user_id}/settings`}
            name={`${params.row.first_name} ${params.row.last_name}`}
          />
        ),
        flex: 0.5,
        minWidth: 100,
        editable: true,
        disableColumnMenu: true,
      },
      {
        field: 'locations',
        headerName: 'Locations',
        flex: 1,
        minWidth: 250,
        disableColumnMenu: false,
        valueGetter: params => params.row.locations.join(', '),
      },
    ],
    []
  );

  return (
    <WidgetWrapper
      {...props}
      renderExtraControls={() => (
        <>
          <AppIconButton
            variant="paper"
            isSmall
            isBorder
            onClick={getSharedFile}
            disabled={downloadButtonLoading}>
            <RiDownloadCloud2Line size="20px" color={theme.palette.text.primary} />
          </AppIconButton>
        </>
      )}>
      <AppDataGrid
        rows={workers}
        getRowId={row => row.user_id}
        columns={columns}
        noPaper
        rowHeight={80}
        height="calc(100% - 50px)"
        rowCount={total}
        loading={loading}
        sortModel={sortModel}
        pageSize={GRID_PAGE_SIZE}
        paginationMode="server"
        sortingMode="server"
        rowsPerPageOptions={[GRID_PAGE_SIZE]}
        onPageChange={handlePageChange}
        onSortModelChange={handleSortModelChange}
        disableSelectionOnClick
      />
    </WidgetWrapper>
  );
};
