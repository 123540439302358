import { makeStyles, Theme } from '@material-ui/core';

import { monochrome } from '../../theme/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '24px',
    maxWidth: '752px',
    borderRadius: '12px',
  },
  controlsContainer: {
    border: `1px solid ${theme.palette.type === 'dark' ? monochrome.dark : monochrome.light}`,
    borderRadius: '8px !important',
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  mainAccordionWrapper: {
    padding: '12px 16px',
    '& .MuiAccordionSummary-root.Mui-expanded': {
      margin: 0,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer',
  },
  defaultCheckbox: {
    opacity: 0.5,

    color: `${theme.palette.primary.main} !important`,
  },
}));
