import React, { useEffect } from 'react';

import { Box } from '@material-ui/core';

import { PageLoading } from '@vyce/core/src/components';
import { CustomDetailsFieldsModule, ExportingTimeLogsModule } from '@vyce/core/src/modules';

import { AutomaticClockOut, TimeLogsReporting } from './components';
import { useSettingsData } from './hooks';

interface Props {
  companyId?: string;
}

export const TimeSettings: React.FC<Props> = ({ companyId }) => {
  const {
    settings,
    endDay,
    countAutoClockOut,
    loading,
    expanded,
    signalForRefreshingId,
    setNewSignalForRefreshingId,
    getPeriods,
    setExpanded,
    onPeriodChange,
    getPreferences,
    updatePreferences,
  } = useSettingsData({ companyId });

  useEffect(() => {
    getPeriods();
    getPreferences();
  }, []);

  return (
    <>
      {loading ? (
        <Box height="calc(100vh - 200px)">
          <PageLoading />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gridGap={24}>
          <TimeLogsReporting
            endDay={endDay}
            settings={settings}
            expanded={expanded}
            setExpanded={setExpanded}
            onPeriodChange={onPeriodChange}
          />
          <AutomaticClockOut countAutoClockOut={countAutoClockOut} updatePreferences={updatePreferences} />
          <CustomDetailsFieldsModule
            companyId={companyId}
            setNewSignalForRefreshingId={setNewSignalForRefreshingId}
          />

          <ExportingTimeLogsModule companyId={companyId} signalForRefreshingId={signalForRefreshingId} />
        </Box>
      )}
    </>
  );
};
