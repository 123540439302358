import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Circle, MapContainer, Polygon, TileLayer } from 'react-leaflet';
import { Box, Typography, useTheme } from '@material-ui/core';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { LatLngExpression, LatLngLiteral, Map } from 'leaflet';
import debounce from 'lodash/debounce';

import { MapMarker, TimeMember, TrackEvent, TrackEventType } from '@vyce/core/src/types';
import { useQuery } from '@vyce/core/src/hooks';
import { getMapViewEventsRequest } from '@vyce/core/src/api/time';
import markerLight from '@vyce/core/src/assets/svg/marker.svg';
import markerDark from '@vyce/core/src/assets/svg/marker-dark-mode.svg';
import markerGrey from '@vyce/core/src/assets/svg/marker-grey.svg';
import { useInterval } from '@vyce/core/src/hooks/useInterval';
import { SITE_RADIUS } from '@vyce/core/src/modules/timeModule/constants';
import { TimeModuleContext } from '@vyce/core/src/contexts';
import { useTabFocus } from '@vyce/core/src/hooks/useTabFocus';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { LONDON_POSITION, MAP_BOX_ATTRIBUTION } from '../../map/constants';
import { TimeModuleMarker } from '../../map/TimeModuleMarker';
import { AppMarker } from '../../map/AppMarker';
import { useMapStyle } from '../../map/hooks/useMapStyle';

interface Props {
  selectedCompanyId: string;
}

interface TimeModuleMarker extends MapMarker {
  eventTime?: string;
}

const ZOOM = 9;
const mapContainerStyles = { height: '100%', width: '100%', borderRadius: '15px' };
const containerStyles = { height: '100%', width: '100%' };

export const LiveTimeLocationMap = ({ selectedCompanyId, ...rest }: Props & WidgetWrapperProps) => {
  const { handleServerError } = useContext(NotificationContext);
  const { mapStyleId, tileLayerUrl } = useMapStyle();
  const [markers, setMarkers] = useState<TimeModuleMarker[]>([]);
  const { isTabFocused } = useTabFocus();
  const theme = useTheme();
  const query = useQuery();
  const siteId = query.get('site_id');
  const { locations } = useContext(TimeModuleContext);
  const mapRef = useRef<Map | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const locationsToDisplay = useMemo(
    () => locations.filter(loc => (siteId ? siteId === loc.uuid : true)),
    [locations, siteId]
  );

  const locationsWithCircleArea = useMemo(
    () => locationsToDisplay.filter(loc => !!loc.center && !loc.polygon),
    [locationsToDisplay, siteId]
  );

  const locationsWithPolygon = useMemo(
    () => locationsToDisplay.filter(loc => !!loc.center && !!loc.polygon),
    [locationsToDisplay, siteId]
  );

  const center = useMemo(() => {
    const centerLoc = locationsToDisplay.find(loc => !!loc.center);
    if (centerLoc) {
      return centerLoc.center;
    }
    return LONDON_POSITION;
  }, [locationsToDisplay]);

  const getWorkersPositions = async () => {
    if (!selectedCompanyId || !isTabFocused) {
      return;
    }
    try {
      const res = await getMapViewEventsRequest(selectedCompanyId, siteId);
      const markers: TimeModuleMarker[] = res.data.items.map(
        (item: { worker: TimeMember; event: TrackEvent }) => ({
          lat: item.event?.lon || 0,
          lon: item.event?.lat || 0,
          name: `${item.worker?.first_name} ${item.worker?.last_name}`,
          eventTime: item.event.timestamp,
          picture: item.event?.icon_url
            ? item.event?.icon_url
            : item.event?.event_type === TrackEventType.CLOCK_OUT
            ? markerGrey
            : theme.palette.type === 'dark'
            ? markerDark
            : markerLight,
          id: item.worker?.uuid,
        })
      );
      setMarkers(markers.filter(marker => marker.lat && marker.lon));
    } catch (e) {
      handleServerError(e);
    }
  };

  useInterval(getWorkersPositions, 15000);

  useEffect(() => {
    if (selectedCompanyId) {
      getWorkersPositions();
    }
  }, [selectedCompanyId, siteId]);

  useEffect(() => {
    const container = containerRef.current;

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        if (mapRef?.current) {
          mapRef.current.invalidateSize();
        }
      }, 100)
    );

    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, []);

  return (
    <WidgetWrapper {...rest}>
      {locationsToDisplay.length ? (
        <div ref={containerRef} style={containerStyles}>
          <MapContainer
            key={mapStyleId}
            scrollWheelZoom={false}
            doubleClickZoom
            style={mapContainerStyles}
            center={center}
            zoom={ZOOM}
            whenCreated={map => {
              setTimeout(() => map.invalidateSize(), 100);
              mapRef.current = map;
            }}>
            <TileLayer
              // url={freeUrl}
              // attribution={CARTO_BOX_ATTRIBUTION}
              url={tileLayerUrl}
              attribution={MAP_BOX_ATTRIBUTION}
            />
            <MarkerClusterGroup chunkedLoading>
              {markers.map(marker => (
                <TimeModuleMarker
                  picture={marker.picture}
                  workerName={marker.name}
                  key={marker.id}
                  position={[marker.lat as number, marker.lon as number]}
                />
              ))}
            </MarkerClusterGroup>
            <>
              {locationsWithCircleArea.map(loc => (
                <Box key={loc.uuid}>
                  <Circle
                    center={loc.center as LatLngLiteral}
                    pathOptions={{ color: theme.palette.primary.main }}
                    radius={SITE_RADIUS}
                  />
                  <AppMarker isMe popupText={loc?.name} position={loc.center as LatLngLiteral} />
                </Box>
              ))}
              {locationsWithPolygon.map(loc => (
                <Box key={loc.uuid}>
                  <Polygon
                    pathOptions={{ color: theme.palette.primary.main }}
                    positions={
                      loc.polygon?.coordinates[0].map(item => ({
                        lat: item[1],
                        lng: item[0],
                      })) as LatLngExpression[]
                    }
                  />
                  <AppMarker isMe popupText={loc?.name} position={loc.center as LatLngLiteral} />
                </Box>
              ))}
            </>
          </MapContainer>
        </div>
      ) : (
        <Box display="flex" height="100%" justifyContent="center" alignItems="center">
          <Typography>No Locations found...</Typography>
        </Box>
      )}
    </WidgetWrapper>
  );
};
