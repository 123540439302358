import { Box, useTheme } from '@material-ui/core';
import { RiDownloadCloud2Line } from 'react-icons/ri';

import { PieChart } from '@vyce/core/src/modules/widgetsModule/components';
import { PageLoading, AppIconButton } from '@vyce/core/src/components';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { useData } from './hooks';
import { Props } from './types';

/* On Site Trades */

export const OnSiteTradesWidget = (props: Props & WidgetWrapperProps) => {
  const { widgetData, loading, downloadButtonLoading, getSharedFile, handleChartClick } = useData(props);
  const theme = useTheme();

  return (
    <WidgetWrapper
      {...props}
      renderExtraControls={() => (
        <>
          <AppIconButton
            variant="paper"
            isSmall
            isBorder
            onClick={getSharedFile}
            disabled={downloadButtonLoading}>
            <RiDownloadCloud2Line size="20px" color={theme.palette.text.primary} />
          </AppIconButton>
        </>
      )}>
      <Box height="100%" width="100%" display="flex" flexDirection="column" alignItems="center">
        {loading ? (
          <PageLoading />
        ) : (
          <PieChart data={widgetData} withPercentage={false} handleChartClick={handleChartClick} />
        )}
      </Box>
    </WidgetWrapper>
  );
};
