import React, { useMemo } from 'react';

import { Box, Typography, MenuItem, MenuList, Button, useTheme } from '@material-ui/core';
import { FormProvider } from 'react-hook-form';
import noop from 'lodash/noop';
import { CgArrowsExpandRight } from 'react-icons/cg';
import { IoSearchSharp } from 'react-icons/io5';

import { AppSelect } from '@vyce/core/src/components/inputs';
import { ConfirmDialog, GridActions, GridCellWithAvatar, AppIconButton } from '@vyce/core/src/components';
import { ControlledAutocomplete } from '@vyce/core/src/components/controlled-inputs';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { getAvatar } from '@vyce/core/src/utils/getAvatar';
import { getUKFormattedDate, getTime } from '@vyce/core/src/utils/dates';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';
import { AppSearchInput } from '@vyce/core/src/components/inputs/AppSearchInput';
import { EnrichedColumnProps } from '@vyce/core/src/components/InfiniteTable/types';
import { InfiniteTable } from '@vyce/core/src/components/InfiniteTable';
import { ExpandedMenuButton } from '@vyce/core/src/components/Dashboard/ExpandedMenuButton';
import { RiDownloadCloud2Line } from 'react-icons/ri';

import { useWidgetData } from './hooks';
import { ExtendedLocationUserDTO, Props } from './types';
import { useStyles } from './styles';
import { statusesEnum, statusOptions, statuses } from './config';
import { SelectorCustomValue } from './components/SelectorCustomValue';

/* Location Users Widget */
export const LocationUsersWidget = (props: Props & WidgetWrapperProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    locationUsers,
    locationSelectOptions,
    total,
    loading,
    isSmallView,
    selectedStatusId,
    methods,
    isConfirmDeleteDialogOpen,
    candidateToRemove,
    closeGridAction,
    isMobile,
    handleSearchChange,
    isLoadingList,
    sortModel,
    isShowSearchField,
    downloadButtonLoading,
    setShowSearchFieldTrue,
    getSharedFile,
    setSmallView,
    handleSortModelChange,
    getLocationUsers,
    resetLoadingList,
    setSelectedStatusId,
    handleRemoveUser,
    closeConfirmDialog,
    removeUserFromLocation,
  } = useWidgetData(props);

  const columns: EnrichedColumnProps<ExtendedLocationUserDTO>[] = useMemo(
    () => [
      {
        field: 'full_name',
        headerName: 'Name',
        flex: 1,
        width: 200,
        sortable: false,
        renderCell: row => (
          <GridCellWithAvatar
            avatarUrl={row.photo_url}
            avatarPlaceholder={getAvatar(row?.gender)}
            name={`${row.full_name}`}
            link={`/time/users/${row?.full_name.split(' ')[0]}_${row?.user_id}/logs`}
            primaryBorder={row.status === statusesEnum.clocked_in}
            neutralBorder={row.status === statusesEnum.clocked_out}
            warningBorder={row.status === statusesEnum.not_clocked_in}
          />
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.8,
        width: 200,
        sortable: false,
        valueGetter: row => statuses[row.status as keyof typeof statuses],
      },
      {
        field: 'location',
        headerName: 'Location',
        flex: 0.8,
        width: 200,
        sortable: false,
        valueGetter: row => row.location || '-',
      },
      {
        field: 'last_clock_event',
        headerName: 'Last Clock Event',
        flex: 0.8,
        width: 200,
        sortable: false,
        valueGetter: row =>
          row?.last_clock_event
            ? `${getTime(row?.last_clock_event)} ${getUKFormattedDate(row?.last_clock_event)}`
            : '-',
      },
      {
        field: 'customCell',
        headerName: 'Actions',
        width: 80,
        sortable: false,
        renderCell: row => (
          <Box display="flex" width="100%">
            <GridActions close={closeGridAction}>
              <MenuList>
                <MenuItem
                  disabled={row.status === statusesEnum.not_clocked_in}
                  onClick={() => handleRemoveUser(row as ExtendedLocationUserDTO, row.location)}>
                  Remove from this Location
                </MenuItem>
                <MenuItem onClick={() => handleRemoveUser(row as ExtendedLocationUserDTO, 'all')}>
                  Remove from all Locations
                </MenuItem>
              </MenuList>
            </GridActions>
          </Box>
        ),
      },
    ],
    [closeGridAction, handleRemoveUser]
  );

  const bigHeaderView = useMemo(
    () => (
      <>
        <AppSearchInput
          onChange={handleSearchChange}
          isExtraSmall
          isBorder
          smallRounding
          expanded={!isMobile}
        />

        <AppIconButton
          variant="paper"
          isSmall
          isBorder
          onClick={getSharedFile}
          disabled={downloadButtonLoading}>
          <RiDownloadCloud2Line size="20px" color={theme.palette.text.primary} />
        </AppIconButton>
      </>
    ),
    [isMobile, handleSearchChange, downloadButtonLoading]
  );

  const handleStatusChange = (event: any) => {
    setSelectedStatusId(event.target.value);
  };

  return (
    <WidgetWrapper
      {...props}
      withExpandButton={!isSmallView}
      setSmallViewStatus={(value: boolean) => {
        setSmallView(value);
      }}
      renderExtraControls={({ handleFullscreenStatusChange }) =>
        isSmallView ? (
          <ExpandedMenuButton>
            <>
              <Button
                onClick={handleFullscreenStatusChange}
                endIcon={<CgArrowsExpandRight size="16px" />}
                variant="outlined"
                size="small"
                hidden={isMobile}>
                Expanded
              </Button>

              <Button
                variant="outlined"
                size="small"
                endIcon={<RiDownloadCloud2Line size="16px" />}
                onClick={getSharedFile}>
                Download
              </Button>

              {!isShowSearchField && (
                <Button
                  variant="outlined"
                  size="small"
                  endIcon={<IoSearchSharp size="16px" />}
                  onClick={setShowSearchFieldTrue}>
                  Search
                </Button>
              )}
            </>
          </ExpandedMenuButton>
        ) : (
          bigHeaderView
        )
      }>
      <Box width="100%" height="100%" display="flex" flexDirection="column" gridGap={12}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(noop)}>
            <Box display="flex" flexDirection="column" gridGap={20}>
              {isSmallView && isShowSearchField && (
                <Box width="200px">
                  <AppSearchInput
                    onChange={handleSearchChange}
                    isSmall
                    isBorder
                    fullWidth
                    expanded={!isMobile}
                  />
                </Box>
              )}
              <Box
                width="100%"
                display="grid"
                gridTemplateColumns={isMobile ? '1fr' : 'repeat(auto-fill, minmax(300px, 1fr))'}
                gridGap={12}>
                <Box display="flex" width="100%" alignItems="center" gridGap={8}>
                  <Box>
                    <Typography noWrap>Workers on</Typography>
                  </Box>
                  <ControlledAutocomplete
                    name="location"
                    margin="none"
                    items={locationSelectOptions}
                    label=""
                  />
                </Box>

                <Box display="flex" width="100%" alignItems="center" gridGap={8}>
                  <Typography>with</Typography>
                  <AppSelect
                    onChange={handleStatusChange}
                    value={selectedStatusId}
                    labelId="select-status"
                    fullWidth
                    className={classes.selector}>
                    {statusOptions.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        <Box display="flex" flexDirection="column">
                          <SelectorCustomValue statusValue={item.value as keyof typeof statusesEnum} />
                        </Box>
                      </MenuItem>
                    ))}
                  </AppSelect>
                </Box>
              </Box>
            </Box>
          </form>
        </FormProvider>

        <InfiniteTable
          data={locationUsers}
          columns={columns}
          rowCount={total}
          rowHeight={54}
          height="calc(100% - 78px)"
          pageSize={GRID_PAGE_SIZE}
          isLoadingList={isLoadingList}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          onLoadMore={getLocationUsers}
          resetLoadingList={resetLoadingList}
        />

        <ConfirmDialog
          handleClose={closeConfirmDialog}
          open={isConfirmDeleteDialogOpen}
          confirmText="Yes, remove"
          title="Are you sure?"
          cancelText="Cancel"
          subtitle={`Do you want to remove ${candidateToRemove?.user.full_name} from ${
            candidateToRemove?.location === 'all' ? 'all Locations' : candidateToRemove?.user.location ?? '-'
          }? This action cannot be undone.`}
          handleConfirm={removeUserFromLocation}
        />
      </Box>
    </WidgetWrapper>
  );
};

/* Шаринг виджета отправил на ревью, в кратце, вот новые эндпоинты:
/public/v1/monitoring/companies/{company_id}/workers/checked_in/export_to_xlsx + 
/public/v1/monitoring/companies/{company_id}/workers/checked_out/export_to_xlsx + 
/public/v1/monitoring/companies/{company_id}/workers/did_not_clock_out/export_to_xlsx + 
/public/v1/monitoring/companies/{company_id}/workers/no_rate/export_to_xlsx + 
/public/v1/monitoring/companies/{company_id}/location/workers/export_to_xlsx
/public/v1/time_logs/companies/{company_id}/widgets/workers_worked_time_overview/export_to_xlsx +
/public/v1/time_logs/companies/{company_id}/widgets/top_performing_workers_last_week/export_to_xlsx + 
/public/v1/time_logs/companies/{company_id}/widgets/on_site_trades/export_to_xlsx + 
Как писал выше, пэйлоад тот же, что и на эндпоинт виджета (e */
