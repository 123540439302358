import { makeStyles, Theme } from '@material-ui/core';
import { monochrome } from '../../theme/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '24px',
    maxWidth: '752px',
    borderRadius: '12px',
  },
  controlsContainer: {
    border: `1px solid ${theme.palette.type === 'dark' ? monochrome.dark : monochrome.light}`,
    borderRadius: '8px !important',
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  mainAccordionWrapper: {
    padding: '12px 16px',
    '& .MuiAccordionSummary-root.Mui-expanded': {
      margin: 0,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  addFieldButton: {
    cursor: 'pointer',
  },

  customInput: {
    width: '100%',
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },

    '& .MuiInputBase-input': {
      padding: '0px 0 2px',
    },

    '& .MuiInputBase-root': {
      fontSize: '13px',
      color: theme.palette.type === 'dark' ? monochrome.lightest : monochrome.darker,
    },

    '& .MuiInput-underline:before': {
      borderColor: '#A0A3BD',
    },

    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: '#A0A3BD',
    },
  },
  deleteButton: {
    cursor: 'pointer',
  },
}));
