import React, { useContext, useMemo } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
  useTheme,
} from '@material-ui/core';
import { GridColDef } from '@mui/x-data-grid';
import { Theme } from '@material-ui/core/styles';
import { FiDownloadCloud } from 'react-icons/fi';

import { DeviceContext } from '../../../contexts';
import { MAIN_CONTAINER_ID } from '../../../constants';
import { ButtonTitleWithLoading } from '../../../components/ButtonTitleWithLoading';
import { CIS300Submit, CISReport } from '../../../types';
import { AppDataGrid, AppIconButton } from '../../../components';
import { formatTableDate } from '../../../utils';

interface Props {
  open: boolean;
  handleClose: () => void;
  submits: CIS300Submit[];
  submit: Function;
  loading: boolean;
  handleDownload: (report?: CISReport) => void;
  report?: CISReport;
}

export const CISSubmitsDialog: React.FC<Props> = ({
  open,
  handleClose,
  submits,
  submit,
  loading,
  handleDownload,
  report,
}) => {
  const { isMobile } = useContext(DeviceContext);
  const theme = useTheme<Theme>();

  const submitsWithId = useMemo(
    () =>
      submits.map((submit, index) => ({
        ...submit,
        id: index,
      })),
    [submits]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'next_payment_attempt',
        headerName: 'Submitted on',
        flex: 0.35,
        disableColumnMenu: true,
        valueFormatter: params => formatTableDate(params.row?.created_at, false),
        minWidth: 140,
      },
      {
        field: 'amount',
        headerName: 'By',
        flex: 0.3,
        disableColumnMenu: true,
        valueFormatter: params => `${params.row.user.first_name} ${params.row.user.last_name}`,
        minWidth: 160,
      },
      {
        field: '',
        headerName: 'Download',
        disableColumnMenu: true,
        sortable: false,
        renderCell: () => (
          <Box display="flex" width="100%" justifyContent="center">
            <AppIconButton
              variant="paper"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={() => handleDownload(report)}>
              <FiDownloadCloud color={theme.palette.text.secondary} />
            </AppIconButton>
          </Box>
        ),
        minWidth: 40,
      },
    ],
    [handleDownload, report, theme.palette.text.secondary]
  );

  return (
    <Dialog
      container={document.getElementById(MAIN_CONTAINER_ID)}
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Hold up!</DialogTitle>

      <DialogContent>
        <Box minWidth={450}>
          <DialogContentText>
            <Typography variant="caption">This CIS 300 has been submitted before.</Typography>
          </DialogContentText>

          <AppDataGrid
            noPaper
            height="300px"
            rows={submitsWithId}
            getRowId={row => row.id}
            loading={loading}
            columns={columns}
            disableSelectionOnClick
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button style={{ width: 144 }} size="large" variant="outlined" onClick={handleClose}>
          No, cancel
        </Button>

        <Button
          style={{ width: 144 }}
          size="large"
          color="primary"
          onClick={() => submit()}
          variant="contained">
          <ButtonTitleWithLoading title="Yes, submit" loaderVariant="paper" loading={loading} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
