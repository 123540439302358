import React, { useContext } from 'react';

import { Box } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CommonNavBarBlock } from '@vyce/core/src/components';
import { DeviceContext, TeamsPageProvider } from '@vyce/core/src/contexts';
import { getPaddingForContent } from '@vyce/core/src/utils';

import {
  TeamMembersModule,
  TeamModule,
  TeamsDashboardModule,
  TeamsListModule,
  TeamsSettings,
} from '../../modules';
import { TEAMS_TABS } from './config';
import { useTeamsData } from './hooks';
import { LegendCompanyFilter } from '../main/components/LegendCompanyFilter';
import { useStyles } from './styles';

export const Teams = () => {
  const { isMobile } = useContext(DeviceContext);

  const { contextValue } = useTeamsData();
  const classes = useStyles();
  const padding = getPaddingForContent(isMobile);

  return (
    <TeamsPageProvider value={contextValue}>
      <Box height="100%">
        <Box className={classes.moduleContainer}>
          <CommonNavBarBlock tabItems={TEAMS_TABS}>
            <LegendCompanyFilter />
          </CommonNavBarBlock>

          <Box padding={padding} display="flex" flexDirection="column" className={classes.container}>
            <Switch>
              <Route path="/teams/dashboard" component={TeamsDashboardModule} />
              <Route path="/teams/members" component={TeamMembersModule} />
              <Route path="/teams/list/:team" component={TeamModule} />
              <Route path="/teams/list" component={TeamsListModule} />
              <Route path="/teams" render={() => <Redirect to={{ pathname: '/teams/dashboard' }} />} />
            </Switch>
          </Box>
        </Box>
      </Box>
    </TeamsPageProvider>
  );
};
